import * as components from './src/components'
import AppService from '@/services/app.service';
import router from '@/router';

export default {
  // called by Vue.use(FirstPlugin)
  install(Vue) {

    // register the component with vue
    Object.values(components).forEach(component => {
      Vue.component(component.name, component)
    })

    // create a mixin
    Vue.mixin({
      beforeCreate () {
        AppService.addRoutes(this,[
          {
            path: '/einnahmen',
            name: 'Einnahmen',
            component: 'liox-mediathek-shop/src/views/einnahmen',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_USER'],
              product: 'Starter'
            },
          },
          {
            path: '/',
            name: 'video-shop',
            component: 'liox-mediathek-shop/src/views/video-shop',
            meta: {}
          },
          {
            path: '/subcheckout',
            name: 'subcheckout',
            beforeEnter: router.guard,
            component: 'liox-mediathek-shop/src/views/subcheckout',
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_USER', 'ROLE_END_USER'],
              product: 'Starter'
            }
          },
          {
            path: '/ageVerification',
            name: 'ageverification',
            component: 'liox-mediathek-shop/src/views/ageVerification',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_USER'],
              product: 'Starter'
            },
          },
          {
            path: '/shop-video/:id',
            name: 'shop-video',
            component: 'liox-mediathek-shop/src/views/shop-video',
            meta: {}
          },
          {
            path: '/order-checkout',
            name: 'ccbill_checkout',
            component: 'liox-mediathek-shop/src/views/ccbill_checkout',
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_USER', 'ROLE_END_USER'],
              product: 'Starter'
            },
          }
        ]);
      },

      created() {
        AppService.addLinks(this,[
          {
            path: '/einnahmen',
            name: 'Einnahmen',
            icon: 'mdi-currency-eur',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_USER'],
              product: 'Starter'
            },
            index: 1
          }
        ])

        AppService.extendCoreComponents([
          'components/video/videoDataTable',
          'components/profile/userDetails',
          'components/profile/registerForm',
          'components/player/videoInsightsActions/vActions'
        ])
        
      }
    });
  },
};
