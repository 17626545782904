import Vue from 'vue';
import Router from 'vue-router';
import userRole from '@/services/userRoleService';
import AppService from '@/services/app.service';
import store from '@/store/Index';

// Utility functions
// function getUserFromStorage(next) {
//   const user = localStorage.getItem('user');
//   if (!user || user === "undefined") {
//     next('/');
//     return null;
//   }
//   return JSON.parse(user);
// }

function getUserFromStorage() {
  try {
    const user = localStorage.getItem('user');
    if (!user || user === "undefined") {
      return null;
    }
    return JSON.parse(user);
  } catch (error) {
    console.error('Fehler beim Laden des Users:', error);
    return null;
  }
}

function isLoggedIn(user) {
  return !!user;
}

function redirectTo(next, path) {
  next(path);
}

// Redirect logic based on user role and environment
function handleAccessControl(to, next, user) {
  const isProduction = process.env.NODE_ENV === 'production';
  const isCreatorSubActive = AppService.isCreatorSubActive();
  const isTester = AppService.isTester();
  const hasProduct = AppService.hasProduct(AppService.getProducts(to.meta.product));

  if (isProduction && to.name !== 'checkout' && !isCreatorSubActive && !isTester) {
    return user.role === 'ROLE_END_USER' ? redirectTo(next, '/install') : redirectTo(next, '/checkout');
  }

  if (isProduction && hasProduct) {
    next();
  } else if (!isProduction) {
    next();
  } else {
    redirectTo(next, '/checkout');
  }
}

// Router guard
function guard(to, from, next) {
  try {
    const user = getUserFromStorage();
    if (!user) {
      next('/');
      return;
    }
    
    if (isLoggedIn(user) && userRole.hasRole(to.meta.roles)) {
      if (from.path === to.path && !from.meta.reload) {
        to.meta.reload = true;
        next();
      } else {
        handleAccessControl(to, next, user);
      }
    } else {
      next('/');
    }
  } catch (error) {
    console.error('Fehler im Navigation Guard:', error);
    next('/'); // oder next('/error') je nach Anforderungen
  }
}
// function guard(to, from, next) {
//   const user = getUserFromStorage();
//   if (!user) {
//     next('/');
//     return;
//   }
//   if (isLoggedIn(user) && userRole.hasRole(to.meta.roles)) {
//     // Check if the next route is the same as the current route
//     if (from.path === to.path && !from.meta.reload) {
//       // Set a meta field to indicate that this is a reload
//       to.meta.reload = true;
//       next();
//     } else {
//       handleAccessControl(to, next, user);
//     }
//   }
// }


Vue.use(Router)

const router = new Router ({

  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Authentication routes
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/global/login.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('./views/global/register.vue')
    },
    {
      path: '/users/:id/activate',
      name: 'activate',
      component: () => import('./views/global/activate.vue')
    },
    {
      path: '/passwordReset',
      name: 'passwordReset',
      component: () => import('./views/global/passwordReset.vue')
    },
    {
      path: '/users/:id/reset',
      name: 'newPassword',
      component: () => import('./views/global/newPassword.vue')
    },

    // Global routes
    {
      path: '/install',
      name: 'install',
      component: () => import('./views/global/install.vue')
    },
    {
      path: '/Datenschutzerklaerung',
      name: 'Datenschutzerklaerung',
      component: () => import('./views/global/Datenschutzerklaerung.vue')
    },
    {
      path: '/Nutzungsbedingungen',
      name: 'Nutzungsbedingungen',
      component: () => import('./views/global/Nutzungsbedingungen.vue')
    },
    {
      path: '/Impressum',
      name: 'Impressum',
      component: () => import('./views/global/Impressum.vue')
    },
    {
      path: '/content-removal',
      name: 'inhalte-melden',
      component: () => import('./views/global/complaintPolicy.vue')
    },    
    {
      path: '/depicture-removal',
      name: 'abbildungen-melden',
      component: () => import('./views/global/removalPolicy.vue')
    },
    {
      path: '/videos/:id',
      name: 'videoTeilen',
      component: () => import('./views/global/sharedVideo.vue')
    },
    {
      path: '/explicitwarning',
      name: 'Ab 18',
      component: () => import('./views/global/explicitwarning.vue'),
      // meta: {
      //   roles: ['ROLE_TENANT'],
      //   product: 'Starter'
      // },
      // beforeEnter: guard
    },

    // User routes
    {
      path: '/home',
      name: 'Dashboard',
      component: () => import('./views/user/user_dashboard.vue'),
      meta: {
        roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/video/:id',
      name: 'video',
      component: () => import('./views/user/video.vue'),
      meta: {
        roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    // {
    //   path: '/stream/:id',
    //   name: 'stream',
    //   component: () => import('./views/user/liveStream.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: guard 
    // },
    // {
    //   path: '/favorits',
    //   name: 'Favoriten',
    //   component: () => import('./views/user/favorits.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
    //     product: 'Starter'
    //   },
    //   beforeEnter: guard 
    // },
    // {
    //   path: '/live-streams',
    //   name: 'live-streams',
    //   component: () => import('./views/user/live-streams.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: guard 
    // },
    {
      path: '/playlist/:id',
      name: 'playlist',
      component: () => import('./views/user/playlist.vue'),
      meta: {
        roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/playlists',
      name: 'playlists',
      component: () => import('./views/user/playlists.vue'),
      meta: {
        roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/category',
      name: 'category',
      component: () => import('./views/user/categories.vue'),
      meta: {
        roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/userprofil',
      name: 'User Profil',
      component: () => import('./views/user/user_profil.vue'),
      meta: {
        roles: ['ROLE_END_USER', 'ROLE_USER', 'ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard
    },
    {
      path: '/danke',
      name: 'Thank you',
      component: () => import('./views/user/thankyou.vue'),
      meta: {
        roles: ['ROLE_END_USER', 'ROLE_USER', 'ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard
    },
    {
      path: '/ablehnung',
      name: 'Ablehnung',
      component: () => import('./views/user/denied.vue'),
      meta: {
        roles: ['ROLE_END_USER', 'ROLE_USER', 'ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard
    },

    // Admin routes
    {
      path: '/Admin',
      name: 'Dashboard',
      component: () => import('./views/admin/admin_dashboard.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: (to, from, next) => {
        guard(to, from, next)
          store.dispatch('fetch', { endpoint: '/api/v1/videos', state: 'videos'}) 
          store.dispatch('fetch', { endpoint: '/api/v1/stats', state: 'stats'})  
          store.dispatch('fetch', { endpoint: '/api/v1/categories', state: 'categories'})
          store.dispatch('fetch', { endpoint: '/api/v1/config', state: 'config'})
      }
    },
    {
      path: '/user',
      name: 'Benutzer Übersicht',
      component: () => import('./views/admin/user_overview.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/chats',
      name: 'nachrichten',
      component: () => import('./views/admin/chats.vue'),
      meta: {
        roles: ['ROLE_TENANT', 'ROLE_END_USER', 'ROLE_USER'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/einstellungen',
      name: 'Einstellungen',
      component: () => import('./views/admin/einstellungen.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/video-upload',
      name: 'Neues Video hochladen',
      component: () => import('./views/admin/video-upload.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: (to, from, next) => {
        guard(to, from, next)
        store.dispatch('fetch', { endpoint: '/api/v1/config', state: 'config'})  
      }
    },
    {
      path: '/videos',
      name: 'Alle Videos',
      component: () => import('./views/admin/videos.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/Playlisten',
      name: 'Playlisten',
      component: () => import('./views/admin/playlists.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: (to, from, next) => {
        guard(to, from, next)
        store.dispatch('fetch', { endpoint: '/api/v1/playlists', state: 'playlists'})  
      }
    },
    {
      path: '/video/:id/stats',
      name: 'videoStats',
      component: () => import('./views/admin/videoStats.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard 
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('./views/admin/categories.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: (to, from, next) => {
        guard(to, from, next)
        store.dispatch('fetch', { endpoint: '/api/v1/categories', state: 'categories'})
        .catch((error) => {
          console.error('Fehler beim Laden der Daten:', error);
        });
      }
    },
    {
      path: '/stats',
      name: 'Statistik',
      component: () => import('./views/admin/stats.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: (to, from, next) => {
        guard(to, from, next)
        store.dispatch('fetch', { endpoint: '/api/v1/config', state: 'config'})
        store.dispatch('fetch', { endpoint: '/api/v1/stats', state: 'stats'})  
        store.dispatch('fetch', { endpoint: '/api/v1/videos', state: 'videos'}) 
      }
    },
    // Live Streaming
    // {
    //   path: '/streams',
    //   name: 'Streams',
    //   component: () => import('./views/admin/streams.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: (to, from, next) => {
    //     guard(to, from, next)
    //     store.dispatch('fetch', { endpoint: '/api/v1/streams', state: 'streams'})
    //   }
    // },
    // {
    //   path: '/stream_bearbeiten',
    //   name: 'Stream bearbeiten',
    //   component: () => import('./views/admin/stream_bearbeiten.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: guard 
    // },
    // {
    //   path: '/stream_start-options',
    //   name: 'Stream Start - Einstellungen',
    //   component: () => import('./views/admin/stream_start-options.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: guard 
    // },
    // {
    //   path: '/stream_backstage',
    //   name: 'Stream Start - Backstage',
    //   component: () => import('./views/admin/stream_backstage.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: guard 
    // },
    // {
    //   path: '/stream_erstellen',
    //   name: 'Stream erstellen',
    //   component: () => import('./views/admin/stream_erstellen.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT'],
    //     product: 'Premium'
    //   },
    //   beforeEnter: guard 
    // },
    {
      path: '/admin_profil',
      name: 'Admin Profil',
      component: () => import('./views/admin/admin_profil.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
      beforeEnter: guard
    },
    // {
    //   path: '/notification',
    //   name: 'notification',
    //   component: () => import('./views/admin/notification.vue'),
    //   meta: {
    //     roles: ['ROLE_TENANT'],
    //     product: 'Starter'
    //   },
    //   beforeEnter: guard 
    // },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('./views/admin/finalCheckout.vue'),
      meta: {
        roles: ['ROLE_TENANT'],
        product: 'Starter'
      },
    },    
  ]
});

router.afterEach(to => {
  // console.log("Router afterEach",to)
  AppService.addComponents(router)
});

router.guard = guard;

export default router;

