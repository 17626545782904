import APIService from '../services/api.service';
import transcoderService from '../services/webSocket.service';

const state = {
    subscription: {},
    payment: { result: [] },
    auth_user: {},
    cart: { result: [] },
    config: {},
    categorie: {},
    categories: [],
    counterVideoUpload: {
        result: 0
    },
    draft: {},
    drafts: [],
    encodeWebSocket: {
        result: []
    },
    email: {},
    emails: [],
    explicitConsent: {},
    gallery: {},
    galleries: [],
    galleryImages: {},
    image: {},
    images: [],
    licence: {},
    lastSeenStat: [],
    lastSeenVideos: { result: [] },
    optionalDraft: [],
    plan: {},
    plans: [],
    playlist: {},
    playlists: [],
    playlistVideos: {
        result: []
    },
    providers: [],
    product: {},
    products: [],
    services: [],
    search: [],
    slides: {
        result: []
    },
    shop: [],
    subscriptions: {
        result: []
    },
    stats: {
        result: {
            videos: {},
            users: {},
            disk: {},
            streams: {}
        },
    },
    stream: {},
    streams: [],
    trigger: {},
    triggers: [],
    user: {},
    users: [],
    video: {},
    videos: [],
    webshop_user: {},

};

const getters = {
    // get: (state) => (key) => {
    //     if (state.hasOwnProperty(key) && typeof state[key].result !== "undefined") {
    //         return state[key].result;
    //     }
    //     return [];
    // },
    get: (state) => (key) => {
        if (state.hasOwnProperty(key) && typeof state[key].result !== "undefined") {
            // Deep clone the categories array to avoid mutating the state directly
            if (key === "categories") {
                return state[key].result.map(category => ({
                    ...category,
                    videos: category.videos
                        ? category.videos.slice().sort((a, b) => new Date(b.created) - new Date(a.created)) // Sort videos by date
                        : [],
                }));
            }
            return state[key].result;
        }
        return [];
    },
};

const mutations = {
    set: (state, payload) => {
        state[payload.state] = payload.data
    },
    appendToState: (state, payload) => {
        if (!state[payload.state]) {
            state[payload.state] = { result: [] };
        }
        state[payload.state].result = [...state[payload.state].result, ...payload.data];
    },
    new: (state, payload) => {
        if (typeof state[payload.state] !== "undefined" && Array.isArray(state[payload.state])) state[payload.state].result.push(payload.data)
    },
    countVideoUpload: (state, payload) => {
        state.counterVideoUpload.result = payload
    },
    setData: (state, payload) => {
        state.encodeWebSocket.result.push(payload.data);
    },
    setPayment: (state, payload) => {
        if (!state.payment.result.some(item => item.id === payload.id)) {
            state.payment.result.push(payload);
        }
    },
    removePayment: (state, payload) => {
        state.payment.result.splice(payload, 1);
    },
    deleteData: (state, payload) => {
        state.encodeWebSocket.result.splice(payload, 1);
    },
    setExplicit: (state, explicitConsent) => state.explicitConsent = explicitConsent
};

const actions = {

    setPayment({ commit }, val) {
        commit('setPayment', val);
    },

    removePayment({ commit }, val) {
        commit('removePayment', val);
    },

    deleteData({ commit }, payload) {
        commit('deleteData', payload);
    },

    countVideos({ commit }, val) {
        commit('countVideoUpload', val);
    },

    async fetch({ commit, getters }, { endpoint, state, host, header }) {

        try {
            const response = await APIService.get(endpoint, host, header);
            commit('set', { state: state, data: response.data });
            return await Promise.resolve(response.data);
        } catch (error) {
            const { config, response: response_2 } = error;

            if (response_2) {
                const { status } = response_2;

                if (status === 401) {
                    if (typeof getters.get('config') === "undefined") {
                        APIService.logout();
                    }
                    APIService.refreshToken(btoa(`${getters.get('config').licence}:`)).then(() => {
                        return APIService.resolveOrigin(config).then((response_3) => {
                            commit('set', { state: state, data: response_3.data });
                            return Promise.resolve(response_3.data);
                        });
                    }).catch((error_2) => {
                        APIService.setResponse({ message: error_2.response.data.message, color: "red" });
                        APIService.logout();
                    });
                } else if (status === 404) {
                    APIService.setResponse({ message: response_2.data.message, color: "red" });
                } else if (status === 403) {
                    APIService.setResponse({ message: response_2.data.message, color: "red" });
                } else if (status === 500) {
                    APIService.setResponse({ message: response_2.data.message, color: "red" });
                } else if (status === 502) {
                    APIService.setResponse({ message: response_2.data.message, color: "red" });
                } else if (status === 503) {
                    APIService.setResponse({ message: response_2.data.message, color: "red" });
                } else {
                    APIService.setResponse({ message: response_2.data.message, color: "red" });
                    return Promise.reject(error);
                }
            }
        }
    },

    async post({ commit, getters }, { endpoint, state, event, host, header, message = true }) {

        let object = event;
        if (event instanceof Event) {
            object = APIService.getFormData(APIService.getForm(event));
        }

        try {
            const response = await APIService.post(endpoint, object, host, header);
            commit('new', { state: state, data: response.data.result });
            if (message) {
                APIService.setResponse({ message: 'Erfolgreich gespeichert.', color: "green" });
            }
            return await Promise.resolve(response.data);
        } catch (error) {
            const { config, response: { status } } = error;

            const originalRequest = config;
            if (status === 401) {
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then((response_1) => {
                        commit('new', { state: state, data: response_1.data.result });
                        if (message) {
                            APIService.setResponse({ message: 'Der Eintrag wurde erfolgreich erstellt', color: "green" });
                        }
                        return Promise.resolve(response_1.data);
                    });
                }).catch((error_2) => {
                    APIService.setResponse({ message: error_2.response.data.message, color: "red" });
                    APIService.logout();
                });
            }
            else {
                if (message) {
                    APIService.setResponse({ message: error.response.data.message, color: "red" });
                }
                return Promise.reject(error);
            }
        }
    },

    uploadVideo({ getters, commit }, { endpoint, host, files, metadata }) {

        APIService.uploadToken(btoa(`${getters.get('config').licence}:`))
            .then(() => {

                for (var i = 0; i < files.video.length; i++) {

                    const video = files.video[i].file
                    let formData = new FormData();

                    formData.append(`video`, video);
                    formData.append('metadata', JSON.stringify(metadata));

                    let uploadKey = 'upload-' + (new Date()).getTime();
                    APIService.setResponse({ message: 0, respKey: uploadKey }, 'interactivities');

                    var config = {
                        onUploadProgress(progressEvent) {
                            var uploadPercentage = 0;
                            uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            APIService.setProgressResponse({ message: uploadPercentage, respKey: uploadKey });
                        }
                    }

                    APIService.upload(endpoint, host, formData, config.onUploadProgress)
                        .then((response) => {
                            APIService.setResponse({ message: 'Upload erfolgreich. Die Konvertierung des Videos beginnt gleich.', color: 'blue' });
                            APIService.closeProgressResponse(uploadKey);
                            transcoderService.onSubmit(response.data.result)
                            commit('setData', { state: 'encodeWebSocket', data: response.data.result.title });
                            return Promise.resolve(response.data);
                        }).catch((error) => {
                            APIService.closeProgressResponse(uploadKey);
                            APIService.setResponse({ message: 'Es ist ein Fehler aufgetreten.', color: "red" });
                            return Promise.reject(error);
                        })

                } // Ende for
            })
        // Ende then()
    },

    async uploadFiles({ getters }, { endpoint, files, host, metadata, message = true }) {

        let uploadKey = 'upload-' + (new Date()).getTime();
        APIService.setResponse({ message: 0, respKey: uploadKey }, 'interactivities');
        var uploadPercentage = 0;
        var config = {
            onUploadProgress(progressEvent) {
                APIService.setProgressResponse({ message: uploadPercentage, respKey: uploadKey });
                uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
        };
        var formData = APIService.getMultipartFormData(files)
        formData.append('metadata', JSON.stringify(metadata))

        try {
            await APIService.uploadToken(btoa(`${getters.get('config').licence}:`));
            try {
                const response = await APIService.upload(endpoint, host, formData, config.onUploadProgress);
                console.log("response im mainStore", response);
                // APIService.setResponse({startUpload : false, uploadRunning : false});
                APIService.closeProgressResponse(uploadKey);
                if (message) {
                    APIService.setResponse({ message: 'Die Datei wurde erfolgreich hochgeladen', color: "green" });
                }
                return await Promise.resolve(response.data);
            } catch (error) {
                APIService.closeProgressResponse(uploadKey);
                if (message) {
                    APIService.setResponse({ message: `${error}`, color: "red" });
                }
                return await Promise.reject(error);
            }
        } catch (error) {
            if (message) {
                APIService.setResponse({ message: `Test${error}`, color: "red" });
            }
            return await Promise.reject(error);
        }
    },

    async put({ getters }, { endpoint, event, host, header, message = true }) {

        let object = event;
        if (event instanceof Event) {
            object = APIService.getFormData(APIService.getForm(event));
        }

        try {
            const response = await APIService.put(endpoint, object, host, header);
            if (message) {
                APIService.setResponse({ message: 'Der Eintrag ist gespeichert.', color: "green" });
            }
            return await Promise.resolve(response.data);
        } catch (error) {
            const { config, response: { status } } = error;
            const originalRequest = config;
            if (status === 401) {
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then((response_1) => {
                        if (message) {
                            APIService.setResponse({ message: 'Der Eintrag ist gespeichert.', color: "green" });
                        }
                        return Promise.resolve(response_1.data);
                    });
                }).catch((error_2) => {
                    APIService.setResponse({ message: error_2.response.data.message, color: "red" });
                    APIService.logout();
                });
            }
            else {
                if (message) {
                    APIService.setResponse({ message: error.response.data.message, color: "red" });
                }
                return Promise.reject(error);
            }
        }
    },

    async patch({ getters }, { endpoint, event, host, header, message = true }) {

        let object = event;
        if (event instanceof Event) {
            object = APIService.getFormData(APIService.getForm(event));
        }

        try {
            const response = await APIService.patch(endpoint, object, host, header);
            if (message) {
                APIService.setResponse({ message: 'Der Eintrag ist gespeichert.', color: "green" });
            }
            return await Promise.resolve(response.data);
        } catch (error) {
            const { config, response: { status } } = error;
            const originalRequest = config;
            if (status === 401) {
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then((response_1) => {
                        if (message) {
                            APIService.setResponse({ message: 'DDer Eintrag ist gespeichert.', color: "green" });
                        }
                        return Promise.resolve(response_1.data);
                    });
                }).catch((error_2) => {
                    APIService.setResponse({ message: error_2.response.data.message, color: "red" });
                    APIService.logout();
                });
            }
            else {
                if (message) {
                    APIService.setResponse({ message: error.response.data.message, color: "red" });
                }
                return Promise.reject(error);
            }
        }
    },

    async delete({ getters }, { endpoint, host, message = true }) {
        try {
            const response = await APIService.delete(endpoint, host);
            if (message) {
                APIService.setResponse({ message: 'Der Eintrag ist gelöscht.', color: "green" });
            }
            return await Promise.resolve(response.data);
        } catch (error) {
            const { config, response: { status } } = error;
            const originalRequest = config;
            if (status === 401) {
                if (typeof getters.get('config') === "undefined") {
                    APIService.logout();
                }
                APIService.refreshToken(btoa(`${getters.get('config').licence}:`)).then(() => {
                    return APIService.resolveOrigin(originalRequest).then(() => {
                        if (message) {
                            APIService.setResponse({ message: 'Der Eintrag wurde gelöscht.', color: "green" });
                        }
                        return Promise.resolve(response.data);
                    });
                }).catch((error_2) => {
                    APIService.setResponse({ message: error_2.response.data.message, color: "red" });
                    APIService.logout();
                });
            }
            else {
                APIService.setResponse({ message: error.response.data.message, color: "red" });
                return Promise.reject(error);
            }
        }
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};
