<template>
	<modal name="adminVideoBearbeiten">
		<div class="pa-6">
			<span class="headline primary--text">Video bearbeiten</span>
			<v-form 
				v-on:submit.prevent 
				@submit="saveVideoChange($event, get('video').id)" 
				v-model="valid"
			>
				<!-- Titel des Videos -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Titel des Videos:</span>
					</v-col>
					<v-col cols="8">
						<v-text-field
							label="Titel des Videos"
							v-model="get('video').title"
							name="title"
							placeholder="Video Titel"
							:rules="videoTitleRules"
							outlined
							required
							dense
							counter="52"
						/>
					</v-col>
				</v-row>

				<!-- Beschreibung -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Beschreibung:</span>
					</v-col>
					<v-col cols="8">
						<v-textarea
							v-model="get('video').description"
							name="description"
							outlined
							:rules="videoDescriptionRules"
							counter="2000"
						></v-textarea>
					</v-col>
				</v-row>

				<!-- Kategorie -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Kategorie:</span>
					</v-col>
					<v-col cols="8" class="px-3 ma-0">
						<v-select 
							v-if="get('video').categorie != null"
							name="categorie"
							v-model="get('video').categorie.id"
							:items="get('categories')"
							item-text="name"
							item-value="id"
							label="Auswahl einer Kategorie"
							:rules="[v => !!v || 'Bitte eine Kategorie auswählen']"
							outlined
						></v-select>
							
						<v-select
							v-else
							name="categorie"
							v-model="categorie"
							:items="get('categories')"
							item-text="name"
							item-value="id"
							label="Auswahl einer Kategorie"
							outlined
						></v-select>						
					</v-col>
				</v-row>

				<!-- Altersfreigabe -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Altersfreigabe:</span>
					</v-col>
					<v-col cols="8">
						<v-select 
							name="fsk"
							v-model="get('video').fsk"
							:items="fskItems"
							item-text="fsk"
							item-value="id"
							label="Auswahl Altersfreigabe"
							outlined
						></v-select>
					</v-col>
				</v-row>
				<v-divider class="mb-4"></v-divider>
				
				<!-- Im Abo enthalten? -->
				<span class="title mt-2">Preis</span>
				<v-row class="mt-2">
					<v-col cols="4" class="">
						<span class="body-1">Video in Abo enthalten:</span>
						<span class="fas fa-check green--text pl-4" v-if="get('video').subscription == true"></span>
						<p class="caption mt- grey--text">Ist dieses Video für alle Abonnenten verfügbar?</p>
					</v-col>
					<v-col cols="8">
						<div class="d-flex justify-end">
							<span class="mr-4 caption">nein / ja</span>
							<v-switch
								v-model="get('video').subscription"
								name="subscription"
								class="ma-0 pa-0"
							></v-switch>
						</div>
					</v-col>
				</v-row>

				<!-- Preis des Videos -->
				<!-- Nur wenn nicht im Video enthalten -->
				<v-row 
					v-if="get('video').subscription == false" 
				>
					<v-col cols="4">
						<span class="body-1">Preis des Videos:</span>
						<p class="caption mt- grey--text">Lege einen Preis für das Video fest.</p>
					</v-col>
					<v-col cols="8">
						<v-text-field
							label="Preis des Videos"
							v-model="get('video').price"
							type="text"
							pattern="[0-9]*"
							inputMode="numeric"
							name="price"
							placeholder="5,99 €"
							:rules="priceRules"
							outlined
							required
						></v-text-field>
					</v-col>
				</v-row>

				<v-row v-else class="mt-2">
					<v-col cols="4">
						<span class="body-1">Abo zuweisen:</span>
					</v-col>
					<v-col cols="8" class="px-3 ma-0">

						<v-select 
							multiple
							name="plans"
							close-text
							v-model="get('video').plans"
							:items="get('plans')"
							item-text="name"
							item-value="id"
							label="Auswahl eines Plans"
							:rules="[v => !!v || 'Bitte einen Plan auswählen']"
							outlined
							chips
							deletable-chips
						>
							<template v-slot:selection="{ item }">
								<v-chip
									close
									color="primary"
									@click:close="deleteItem(item)"
									outlined
								>
									{{item.name}}
								</v-chip>
							</template>
						</v-select>						

					</v-col>
				</v-row>

				<v-divider class="my-4"></v-divider>

				<!-- Thumbnail -->
				<span class="title mt-0">Aktuelles Thumbnail</span>
				<v-row class="my-4">
					<v-col cols="4">
						<span class="body-1">Thumbnail hochladen:</span>
						<v-img :src="get('video').image" width="250px"></v-img>
					</v-col>
					<v-col col="8">
						<VueFileAgent
							ref="image"
							:deletable="true"
							:meta="true"
							:accept="'image/*'"
							:maxSize="'30MB'"
							:maxFiles="1"
							:errorText="{
								type: 'Falscher Dateityp. Es sind nur Bilder erlaubt.',
								size: 'Dateien dürfen nicht größer als 3GB sein.',
							}"
							@beforedelete="onBeforeDeleteImage($event)"
							@delete="imageDeleted($event)"
							@change="thumbnailUpload(get('video').id)"
							v-model="image"
						>
						<template v-slot:file-preview-new>
							<div key="new" class="file-preview-wrapper grid-box-item grid-block file-preview-new">
							<span class="file-preview">
								<div style="position: absolute; top: 15px; right: 0px; bottom: 0px; left: 0px;">
								<v-icon color="#00C3FF">mdi-image</v-icon>
								<span class="help-text" style="color: '#00C3FF'; font-size: 12px">Bild per Drag &amp; Drop einfügen oder Ordner durchsuchen</span>
								</div>
							</span>
							</div>
						</template>
						</VueFileAgent>
					</v-col>
				</v-row>

				<v-divider class="my-4"></v-divider>
				
				<!-- On Stage? -->
				<!-- Soll das Video auf der Plattform sichtbar sein? -->
				<span class="title my-2">Sichtbarkeit</span>
				<v-row class="mt-2">
					<v-col class="mb-n2">
						<span class="body-1">On Stage?</span>
						<span class="fas fa-check green--text pl-4" v-if="get('video').published == true"></span>
						<p class="caption grey--text mr-12">Das Video ist innerhalb der Plafform sichtbar.</p>
					</v-col>
					<span class="caption pt-5 pr-4">nein / ja</span>
					<v-switch
						name="published"
						v-model="get('video').published"
						class="mr-2"
						></v-switch>
						<!-- @change="autoSaveVideoChange(get('video').id, get('video'))" -->
					</v-row>
					
					
					<!-- Veröffentlichen? -->
					<v-row>
						<v-col class="mb-n2">
							<span class="body-1">Veröffentlichen?</span>
							<span class="fas fa-check green--text pl-4" v-if="get('video').share == true"></span>
							<p class="caption mt- grey--text">Das Video ist ausserhalb der Plafform erreichbar.<br>Es wird ein Link zum teilen und oder einbetten angezeigt.</p>
						</v-col>
						<span class="caption pt-5 pr-4">nein / ja</span>
						<v-switch
							v-model="get('video').share"
							name="share"
							class="mr-2"
						></v-switch>
						<!-- @change="shareItem(get('video')) && autoSaveVideoChange(get('video').id, get('video'))" -->
					</v-row>

					<!-- Snippte und Link zum Teilen -->
					<v-card outlined text class="pa-4" v-if="get('video').share == true">
						<div>
						<p class="px-3 font-weight-bold">Code Snippet zum einbetten</p>
						<div class="d-flex justify-space-between">
							<code id="shareItem" class="mx-2 pa-2">{{shareItem(get('video'))}}</code>
							<v-btn @click="copyCodeSnippet(shareItem(get('video')))" small color="lightblue">
								<v-icon size="16" class="mr-2">mdi-content-copy</v-icon>Kopieren
							</v-btn>
						</div>
					</div>

					<div class="mt-4">
						<p class="px-3 font-weight-bold">Link teilen</p>
						<div class="d-flex pl-3 mt-4 justify-space-between">
							<p class="">{{shareLink(get('video'))}}</p>
							<v-btn @click="copyCodeSnippet(shareLink(get('video')))" small color="lightblue">
								<v-icon size="16" class="mr-2">mdi-content-copy</v-icon>Kopieren
							</v-btn>
						</div>
					</div>

				</v-card>
				
				<v-divider class="my-4"></v-divider>
				<span class="title my-2">Community</span>
				<!-- Comments? -->
				<v-row class="mt-0">
					<v-col class="mb-n2">
						<span class="body-1">Kommentare freischalten?</span>
						<span class="fas fa-check green--text pl-4" v-if="get('video').comment == true"></span>
						<p class="caption grey--text">Mit Freischaltung können registrierte Nutzer <br>unter diesem Video Kommentare verfassen.</p>
					</v-col>
					<span class="caption pt-5 pr-4">nein / ja</span>
					<v-switch
					name="comment"
					v-model="get('video').comment"
					class="mr-2"
					></v-switch>
					<!-- @change="autoSaveVideoChange(get('video').id, get('video'))" -->
				</v-row>
				
				<v-divider class="my-6"></v-divider>
				
				<v-card-actions class="mb-2 mr-1">
					<v-row class="justify-end">
						<v-btn text class="grey--text" @click="close('adminVideoBearbeiten')">abbrechen</v-btn>
						<v-btn class="green--text ml-4" outlined type="submit">Speichern</v-btn>
					</v-row>
				</v-card-actions>
			</v-form>
		</div>
	</modal>
</template>

<script>
import adminVideoBearbeiten from '@/components/modal/adminVideoBearbeiten';
import APIService from '@/services/api.service';
import AppService from '@/services/app.service';

import Multiselect from 'vue-multiselect'

// import AppService from '@/services/app.service';
export default {
	name: 'adminVideoBearbeiten',
	extends: adminVideoBearbeiten,
	components: { Multiselect },
	data(){
		return {
			categorieSelect: [],
			subscriptionSelect: [],
			fskItems: [
				{ id: 0, fsk: 'Ohne Altersbeschränkung' },
				{ id: 6, fsk: 'Ab 6 Jahren' },
				{ id: 12, fsk: 'Ab 12 Jahren' },
				{ id: 16, fsk: 'Ab 16 Jahren' },
				{ id: 18, fsk: 'Ab 18 Jahren' }
			],
			priceRules: [
				v => !!v || 'Preis darf nicht leer sein',
				v => !isNaN(parseFloat(v)) && !isNaN(v - 0) || 'Preis darf nur Zahlen und Komma enthalten',
				v => v >= 0 || 'Preis darf nicht negativ sein'
			],
			selectedOption: null,
			selectedPricingOption: Boolean,
		}
	},
	methods: {

		deleteItem(item){
			this.$store.dispatch('delete', { endpoint: `/api/v1/videos/${this.$store.state.main.video.result.id}/plans/${item.id}`})
			.then(() => {
				this.$store.dispatch('fetch', { endpoint: `/api/v1/videos/${this.$store.state.main.video.result.id}`, state: 'video'});
			})
		},

		itemType(){
			// this.selectedItem = payload.name
			this.selectedPricingOption != this.selectedPricingOption
			console.log("selected item:", this.selectedPricingOption)
		},

		toggleRadio(option) {
			if (this.selectedOption === option) {
				// Wenn dieselbe Option erneut ausgewählt wird, setze sie auf null zurück
				this.selectedOption = null;
			} else {
				this.selectedOption = option;
				// Radiobutton basierend auf dem Ref umschalten
				this.$refs['radio' + option][0].toggle();
			}
		},

		thumbnailUpload(id) {
			setTimeout(() => {
				console.log("image", this.image)
				this.$store.dispatch('uploadFiles',
					{
						endpoint: `/api/v1/videos/${id}/upload`,
						files: { image: this.image },
						metadata: { width: 100, height: 100 },
						message: false
					}
				).then((response) => {
					this.resetForm()
					APIService.setResponse({ message: `Deine Änderungen wurde gespeichert.`, color: "blue" });
				}).catch((error) =>{
					APIService.setResponse({ message: `Das Bild konnte nicht hochgeladen werden`, color: "red" });
				})
			}, 100);
		},

		shareItem(item, style = 'height="500px" width="100%'){
			const src = `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
			let iframe = '';
			if(item.share){
				iframe = `<iframe ${style}" src="${src}" title="${this.$store.state.main.config.result.site_name}"></iframe>`
			}
			return iframe
		},

		shareLink(item){
			return `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
		},

		publishVideo(id){
			var published = this.$store.state.main.video.result.share
			published != published
			this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/videos/${id}`,
					event: { share: published },
					message: false
				}
			)
		},
		stageVideo(id){
			var staged = this.$store.state.main.video.result.published
			staged != staged
			this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/videos/${id}`,
					event: { published: staged },
					message: false
				}
			)
		},

		copyCodeSnippet(item){
			// var copyText = this.shareItem(this.$store.state.main.video.result)
			navigator.clipboard.writeText(item);
			APIService.setResponse({ message: "Kopiert.", color: "blue" })
		},

		close(name) {
			this.$store.dispatch("modal/close", name)
		},

		resetForm(){
			this.image = []
			this.video = []
			this.fileRecordsForUpload = []
		},

		saveVideoChange(e, id){
			let object = APIService.getFormData( APIService.getForm(e));
			object = AppService.formatPlans(object, 'video');
			object.fsk = parseInt(object.fsk);

			this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/videos/${id}`,
					event: object,
					state: 'video',
					message: false
				}
			).then(() => {
				// this.$store.dispatch('fetch', { endpoint: `/api/v1/videos/${id}`, state: 'videos'});
				this.$store.dispatch('fetch', { endpoint: '/api/v1/videos?sort=DESC', state: 'videos'});
				this.$store.dispatch("modal/close", 'adminVideoBearbeiten');
				APIService.setResponse({message: "Änderung gespeichert", color: "green"});
				// console.log("Kommt durch.");
			}).catch((error) =>{
				const {response: {status} } = error;
				APIService.setResponse({message: `Fehler: ${status}`, color: "red"});
			})
		},
		onBeforeDeleteImage: function (fileRecord) {
			var i = this.fileRecordsForUpload.indexOf(fileRecord);
			if (i !== -1) {
				this.fileRecordsForUpload.splice(i, 1);
			} else {
				if (confirm('Das Bild wirklich löschen?')) {
					this.$refs.logo.deleteFileRecord(fileRecord); // will trigger 'delete' event
				}
			}
		},
	}, 

	mounted(){
		this.$store.dispatch('fetch', { endpoint: '/api/v1/plans', state: 'plans'});
	}
}
</script>
